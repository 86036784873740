import React from "react"
import Helmet from "react-helmet"

const Termly = ({title, dek, doc}) => (
  <>
    <Helmet>
      <script type="text/javascript" src="https://app.termly.io/embed-policy.min.js"></script>
    </Helmet>
    <section class="section" id="contact">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 pt-5">
                  <a name="termly" style={{
                    "position":"relative",
                    "top":"-100px"
                  }}/>
                  <div name="termly-embed" data-id={doc} data-type="iframe"></div>
                </div>
            </div>
        </div>
    </section>
  </>
)

export default Termly
