import React from "react"
import { Link } from "gatsby"

import SEO from "../../components/seo"

import Termly from "../../components/termly"

import Hero from "../../components/hero"
import Contact from "../../components/contact"
import Layout from "../../components/layout"

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy" />
    <Hero
      headline={(<span>Privacy Policy.</span>)}
      dek="We'll never sell your data and we always do the right thing to maintain your trust."
      readMore="Read the policy"
      readMoreLink="#termly"
    />
    <Termly doc="e9b56521-11b0-45ba-ba82-8d5ca7f82b58"/>
  </Layout>
)

export default PrivacyPage
